<template>
  <div class="container">
    <form class="form flex-column">
      <div class="row mb-10">
        <!--begin::Label-->
        <label class="col-lg-2 form-label fw-semi-bold">Construction</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-10">
          <!--begin::Row-->
          <div class="row">
            <!--begin::Col-->
            <div
              class="col-xl-3 fv-row mb-3 mb-xl-0"
              v-for="construction in constructions"
              :class="activeRadioBtn(construction.title, form.construction)"
              :key="construction.value"
            >
              <!--begin::Radio button-->
              <label
                :class="[
                  'd-flex flex-stack align-items-start text-start p-5 h-100 border-dashed-long rounded',
                  validateModel($v.form.construction.$error),
                ]"
              >
                <!--begin::Radio-->
                <div class="form-check form-check-custom form-check-solid form-check-sm form-check-primary me-4">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="construction"
                    :value="construction.value"
                    v-model="form.construction"
                    @change="onConstructionUpdate($event.target.value)"
                  />
                </div>
                <!--end::Radio-->

                <!--begin::Info-->
                <div class="flex-grow-1">
                  <h4 class="fs-7 fw-semi-bold">{{ construction.title }}</h4>
                  <div class="small text-gray-700 lh-sm">{{ construction.description }}</div>
                </div>
                <!--end::Info-->
              </label>
              <!--end::Radio button-->
            </div>
            <!--end::Col-->
          </div>
          <!--end::Row-->
        </div>
        <!--end::Col-->
      </div>

      <div v-if="this.construction === 'Fiberglass' && !form.is_fiberglass_custom" class="row mb-4">
        <label class="col-lg-2 form-label fw-semi-bold">Shell</label>
        <div class="col-lg-10">
          <div class="row align-items-start">
            <div class="col-lg-6 col-xl-4 fv-row mb-4 mb-xl-0">
              <px-select
                v-if="fiberglassPoolTemplates"
                v-model="form.fiberglass_pool_template_id"
                placeholder="Select Template"
                :items="fiberglassPoolTemplates.data"
                track-by="id"
                :text-by="['attributes.name']"
                :minimum-results-for-search="Infinity"
                :disabled="form.is_fiberglass_custom"
                select2-id="select2_fiberglass_pool_template_id"
                @input="onFiberglassPoolTemplateUpdate"
              />
            </div>
          </div>
        </div>
      </div>
      <!--      <div v-if="this.construction === 'Fiberglass' && form.is_fiberglass_custom" class="row mb-4">-->
      <!--        <label class="col-lg-2 form-label fw-semi-bold">Shape</label>-->
      <!--        <div class="col-lg-10">-->
      <!--          <div class="row align-items-start">-->
      <!--            <div class="col-lg-6 col-xl-4 fv-row">-->
      <!--              <px-select-->
      <!--                v-model="form.shape"-->
      <!--                placeholder="Select shape"-->
      <!--                :items="shapes"-->
      <!--                track-by="attributes.title"-->
      <!--                :text-by="['attributes.title']"-->
      <!--                :minimum-results-for-search="Infinity"-->
      <!--                select2-id="select2_shape"-->
      <!--                @input="onShapeUpdate"-->
      <!--              />-->
      <!--            </div>-->
      <!--            <div v-if="form.shape === 'Custom'" class="col-lg-6 col-xl-4 fv-row">-->
      <!--              <input-->
      <!--                v-model="form.custom_shape_text"-->
      <!--                type="text"-->
      <!--                class="form-control form-control-solid"-->
      <!--                @change="onCustomShapeTextUpdate"-->
      <!--              />-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div
        v-if="this.construction !== 'Fiberglass' || form.is_fiberglass_custom"
        :class="[
          'row',
          { 'mb-10': this.construction !== 'Fiberglass' },
          { 'mb-4': this.construction === 'Fiberglass' },
        ]"
      >
        <label class="col-lg-2 form-label fw-semi-bold">Shape</label>
        <div class="col-lg-10">
          <div class="row align-items-start">
            <div class="col-lg-6 col-xl-4 fv-row mb-4 mb-xl-0">
              <px-select
                v-model="form.shape"
                placeholder="Select shape"
                :items="shapes"
                track-by="attributes.title"
                :text-by="['attributes.title']"
                :minimum-results-for-search="Infinity"
                select2-id="select2_shape"
                @input="onShapeUpdate"
              />
            </div>
            <div
              v-if="form.shape === 'Custom' && form.is_fiberglass_custom"
              class="col-lg-6 col-xl-4 fv-row mb-4 mb-xl-0"
            >
              <input
                v-model="form.custom_shape_text"
                type="text"
                class="form-control form-control-solid"
                @change="onCustomShapeTextUpdate"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.construction === 'Fiberglass'" class="row mb-10">
        <div class="col-lg-2 form-label fw-semi-bold" />
        <div class="col-lg-10">
          <label class="form-check-sm form-check form-check-custom form-check-solid d-inline-block">
            <input
              v-model="form.is_fiberglass_custom"
              class="form-check-input"
              type="checkbox"
              @change="onIsFiberglassCustomChange"
            />
            <span class="form-check-label">Custom</span>
          </label>
        </div>
      </div>

      <!--      <div class="row mb-10">-->
      <!--        &lt;!&ndash;begin::Label&ndash;&gt;-->
      <!--        <label class="col-lg-2 form-label fw-semi-bold">Shape</label>-->
      <!--        &lt;!&ndash;end::Label&ndash;&gt;-->
      <!--        &lt;!&ndash;begin::Col&ndash;&gt;-->
      <!--        <div class="col-lg-10">-->
      <!--          <div class="row align-items-start">-->
      <!--            <div class="col-lg-6 col-xl-4 fv-row mb-4 mb-xl-0">-->
      <!--              <px-select-->
      <!--                v-model="form.shape"-->
      <!--                placeholder="Select shape"-->
      <!--                :items="shapes"-->
      <!--                track-by="attributes.title"-->
      <!--                :text-by="['attributes.title']"-->
      <!--                :minimum-results-for-search="Infinity"-->
      <!--                select2-id="select2_shape"-->
      <!--                @input="onShapeUpdate"-->
      <!--              />-->
      <!--            </div>-->
      <!--            <div v-if="form.shape === 'Custom'" class="col-lg-6 col-xl-4 fv-row mb-4 mb-xl-0">-->
      <!--              <input-->
      <!--                v-model="form.custom_shape_text"-->
      <!--                type="text"-->
      <!--                class="form-control form-control-solid"-->
      <!--                @change="onCustomShapeTextUpdate"-->
      <!--              />-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        &lt;!&ndash;end::Col&ndash;&gt;-->
      <!--      </div>-->

      <div
        v-if="this.construction === 'Fiberglass' && !form.is_fiberglass_custom && fiberglass_pool_template"
        class="mb-10"
      >
        <div class="row">
          <label class="col-lg-2 form-label fw-semi-bold">Fiberglass Details</label>
          <div class="col-lg-10">
            <div class="row">
              <div class="col-sm-6 col-lg-3 mb-sm-6 mb-lg-0">
                <div class="card bg-light h-100 lh-sm">
                  <div class="card-header pt-4 px-4 pb-0 min-h-auto">
                    <img
                      src="/assets/media/icons/custom/pool.png"
                      alt="Construction"
                      width="30"
                      height="30"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Construction"
                    />
                  </div>
                  <div
                    class="card-body p-4"
                    v-html="replaceBreakWithBrTag(constructions.find((el) => el.title === construction).description)"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3 mb-sm-6 mb-lg-0">
                <div class="card bg-light h-100 lh-sm">
                  <div class="card-header pt-4 px-4 pb-0 min-h-auto">
                    <img
                      src="/assets/media/icons/custom/size.png"
                      alt="Size"
                      width="30"
                      height="30"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Size"
                    />
                  </div>
                  <div class="card-body p-4">
                    <div class="mb-2">
                      <h4 class="h5 mb-0">
                        {{ transformToFtAndIn(fiberglass_pool_template.width).ft }} ft.
                        {{ transformToFtAndIn(fiberglass_pool_template.width).in }} in.
                      </h4>
                      <div class="text-muted">width</div>
                    </div>

                    <div>
                      <h4 class="h5 mb-0">
                        {{ transformToFtAndIn(fiberglass_pool_template.length).ft }} ft.
                        {{ transformToFtAndIn(fiberglass_pool_template.length).in }} in.
                      </h4>
                      <div class="text-muted">length</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="card bg-light h-100 lh-sm">
                  <div class="card-header pt-4 px-4 pb-0 min-h-auto">
                    <img
                      src="/assets/media/icons/custom/square.png"
                      alt="Shape"
                      width="30"
                      height="30"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Shape"
                    />
                  </div>
                  <div class="card-body p-4">
                    <p class="mb-2">
                      {{ fiberglass_pool_template.shape_name }}
                    </p>
                    <p class="mb-0">
                      {{ fiberglass_pool_template.shape }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="card bg-light h-100 lh-sm">
                  <div class="card-header pt-4 px-4 pb-0 min-h-auto">
                    <img
                      src="/assets/media/icons/custom/deep.png"
                      alt="Approx. water depths"
                      width="30"
                      height="30"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Approx. water depths"
                    />
                  </div>
                  <div class="card-body p-4">
                    <div class="mb-2">
                      <h4 class="h5 mb-0">
                        {{ transformToFtAndIn(fiberglass_pool_template.shallow).ft }} ft.
                        {{ transformToFtAndIn(fiberglass_pool_template.shallow).in }} in.
                      </h4>
                      <div class="text-muted">shallow</div>
                    </div>

                    <div>
                      <h4 class="h5 mb-0">
                        {{ transformToFtAndIn(fiberglass_pool_template.deepend).ft }} ft.
                        {{ transformToFtAndIn(fiberglass_pool_template.deepend).in }} in.
                      </h4>
                      <div class="text-muted">deep</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="this.construction === 'Fiberglass' && !form.is_fiberglass_custom && fiberglass_pool_template"
        class="mb-10"
      >
        <div class="row">
          <label class="col-lg-2 form-label fw-semi-bold">Price Details</label>
          <div class="col-lg-10">
            <div class="notice bg-gray-100 rounded border p-3">
              {{ fiberglass_pool_template.pricing_description }}

              <div :class="['fw-bold', { 'mt-2': fiberglass_pool_template.pricing_description }]">
                Starting Price:
                <span class="fs-4 text-primary">
                  {{ $n(fiberglass_pool_template.price_in_cents / 100, 'currency', 'en-CA') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="this.construction !== 'Fiberglass' || form.is_fiberglass_custom" class="row mb-10">
        <!--begin::Label-->
        <label class="col-lg-2 form-label fw-semi-bold">Dimensions</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-10">
          <!--begin::Row-->
          <div class="row align-items-start">
            <!--begin::Col-->
            <div class="col-lg-6 col-xl-4 fv-row mb-4 mb-xl-0">
              <label class="form-label fw-semi-bold text-muted">Width</label>
              <div class="row gx-2">
                <div class="col-md-6">
                  <px-counter
                    v-model="form.width_ft"
                    type="text"
                    :state="$v.form.width_ft.$error"
                    hint="ft."
                    @input="onWidthUpdate"
                  />
                </div>
                <div class="col-md-6">
                  <px-counter
                    v-model="form.width_in"
                    type="text"
                    :state="$v.form.width_in.$error"
                    hint="in."
                    @input="onWidthUpdate"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 fv-row mb-4 mb-xl-0">
              <label class="form-label fw-semi-bold text-muted">Length</label>
              <div class="row gx-2">
                <div class="col-md-6">
                  <px-counter
                    v-model="form.length_ft"
                    type="text"
                    :state="$v.form.length_ft.$error"
                    hint="ft."
                    @input="onLengthUpdate"
                  />
                </div>
                <div class="col-md-6">
                  <px-counter
                    v-model="form.length_in"
                    type="text"
                    :state="$v.form.length_in.$error"
                    hint="in."
                    @input="onLengthUpdate"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 fv-row">
              <div class="mb-4">
                <label class="form-label fw-semi-bold text-muted">Size category</label>
                <input class="form-control form-control-solid text-muted" v-model="form.size_category" readonly />
              </div>
              <div>
                <label class="form-check-sm form-check form-check-custom form-check-solid">
                  <input class="form-check-input" type="checkbox" v-model="isManualSize" />
                  <span class="form-check-label">Change Manually</span>
                </label>
              </div>
              <div v-if="isManualSize" class="mt-8">
                <select
                  :class="['form-select form-select-solid', validateModel($v.form.size_category.$error)]"
                  v-model="form.size_category"
                  @change="onSizeCategoryUpdate"
                >
                  <option>Select a Size</option>
                  <option v-for="size in sizeCategories" :value="size" :key="size">{{ size }}</option>
                </select>
              </div>
            </div>
          </div>
          <!--end::Row-->
        </div>
        <!--end::Col-->
      </div>
      <div v-if="this.construction !== 'Fiberglass' || form.is_fiberglass_custom" class="row mb-10">
        <!--begin::Label-->
        <label class="col-lg-2 form-label fw-semi-bold">Depth</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-10">
          <!--begin::Row-->
          <div class="row">
            <div class="col-lg-6 col-xl-4 fv-row mb-4 mb-lg-0">
              <label class="form-label fw-semi-bold text-muted">Shallow end</label>
              <div class="row gx-2">
                <div class="col-md-6">
                  <px-counter
                    type="text"
                    :state="$v.form.shallow_ft.$error"
                    hint="ft."
                    v-model="form.shallow_ft"
                    @input="onShallowUpdate"
                  />
                </div>
                <div class="col-md-6">
                  <px-counter
                    type="text"
                    :state="$v.form.shallow_in.$error"
                    hint="in."
                    v-model="form.shallow_in"
                    @input="onShallowUpdate"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 fv-row mb-4 mb-lg-0">
              <label class="form-label fw-semi-bold text-muted">Deep end</label>
              <div class="row gx-2">
                <div class="col-md-6">
                  <px-counter
                    v-model="form.deepend_ft"
                    type="text"
                    :state="$v.form.deepend_ft.$error"
                    hint="ft."
                    @input="onDeependUpdate"
                  />
                </div>
                <div class="col-md-6">
                  <px-counter
                    v-model="form.deepend_in"
                    type="text"
                    :state="$v.form.deepend_in.$error"
                    hint="in."
                    @input="onDeependUpdate"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--end::Row-->
        </div>
        <!--end::Col-->
      </div>

      <div class="row mb-10">
        <!--begin::Label-->
        <label class="col-lg-2 form-label fw-semi-bold">Construction details</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-10">
          <textarea-autosize
            v-model="form.construction_details"
            :class="['form-control form-control-solid', validateModel($v.form.construction_details.$error)]"
            rows="1"
            :min-height="42"
            :max-height="500"
            @input.native="onConstructionDetailsUpdate"
          />
        </div>
        <!--end::Col-->
      </div>
    </form>

    <!--begin::Actions-->
    <div class="d-flex flex-stack mt-8 pt-8 border-top">
      <!--begin::Wrapper-->
      <div class="me-4">
        <px-btn color="secondary" :in-process="prevInProcess" :disabled="prevInProcess" @click.native="customGoToPrev">
          Back
        </px-btn>
      </div>
      <!--end::Wrapper-->
      <!--begin::Wrapper-->
      <div class="ms-auto">
        <px-btn
          color="success"
          extended-classes="me-2"
          :in-process="updateInProcess"
          :disabled="updateInProcess"
          @click.native="customOnUpdate"
        >
          Save
        </px-btn>
        <px-btn :in-process="nextInProcess" :disabled="nextInProcess" @click.native="customGoToNext"> Next </px-btn>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Actions-->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { required } from 'vuelidate/lib/validators';

import ProposalWizardMixin from '@/mixins/ProposalWizardMixin';

export default {
  mixins: [ProposalWizardMixin],
  data() {
    return {
      constructionDetailsTemplates: null,
      isManualSize: false,
      form: {
        construction: '',
        shape: '',
        custom_shape_text: '',
        width_ft: '',
        width_in: '',
        length_ft: '',
        length_in: '',
        size_category: '',
        shallow_ft: '',
        shallow_in: '',
        deepend_ft: '',
        deepend_in: '',
        construction_details: '',

        fiberglass_pool_template_id: '',
        is_fiberglass_custom: false,
      },

      fiberglass_pool_template: null,
    };
  },
  validations: {
    form: {
      construction: { required },
      width_ft: { required },
      width_in: { required },
      length_ft: { required },
      length_in: { required },
      size_category: { required },
      shallow_ft: { required },
      shallow_in: { required },
      deepend_ft: { required },
      deepend_in: { required },
      construction_details: { required },
    },
  },
  computed: {
    ...mapGetters({
      message: 'proposalWizard/message',
      isRenovate: 'proposalWizard/isRenovate',
      construction: 'proposalWizard/construction',
      shape: 'proposalWizard/shape',
      customShapeText: 'proposalWizard/customShapeText',
      width: 'proposalWizard/width',
      length: 'proposalWizard/length',
      sizeCategory: 'proposalWizard/sizeCategory',
      shallow: 'proposalWizard/shallow',
      deepend: 'proposalWizard/deepend',
      constructionDetails: 'proposalWizard/constructionDetails',
      constructionDetailsTemplatesItems: 'constructionDetailsTemplates/items',

      fiberglassPoolTemplates: 'fiberglassPoolTemplates/items',
      fiberglassPoolTemplateId: 'proposalWizard/fiberglassPoolTemplateId',
      isFiberglassCustom: 'proposalWizard/isFiberglassCustom',
    }),
    constructions() {
      return [
        {
          title: 'Vinyl',
          value: 'Vinyl',
          description: 'Vinyl lined swimming pool with steel walls and a 32MPa concrete floor',
        },
        {
          title: 'Concrete',
          value: 'Concrete',
          description: 'Concrete pool with 12" walls and 12" floor, reinforced with 15mm steel rebar',
        },
        {
          title: 'Fiberglass',
          value: 'Fiberglass',
          description: 'Fiberglass shell composed of interwoven glass threads covered with a polyester resin',
        },
        {
          title: 'Renovation',
          value: 'Renovate',
          description: 'Pool renovation as per the specifications described in this proposal',
        },
      ];
    },
    sizeCategories() {
      return ['Small', 'Medium', 'Large', 'Extra Large'];
    },
    shapes() {
      return [
        {
          id: '1001',
          attributes: {
            title: 'Rectangular with square corners',
          },
        },
        {
          id: '1002',
          attributes: {
            title: 'Freeform with curves',
          },
        },
        {
          id: '1003',
          attributes: {
            title: '"L" shape rectangular',
          },
        },
        {
          id: '1004',
          attributes: {
            title: 'Kidney',
          },
        },
        {
          id: '1005',
          attributes: {
            title: 'Custom',
          },
        },
      ];
    },
  },
  // watch: {
  //   'form.width_ft'(val) {
  //     this.form.size_category = this.sizeAccordingToWidthAndLength(
  //       val,
  //       `${this.form.length_ft}.${this.form.length_in}`
  //     );
  //     this.setSizeCategory(this.form.size_category);
  //   },
  //   'form.length_ft'(val) {
  //     this.form.size_category = this.sizeAccordingToWidthAndLength(val, `${this.form.width_ft}.${this.form.width_in}`);
  //     this.setSizeCategory(this.form.size_category);
  //   },
  // },
  async mounted() {
    await this.constructionDetailsTemplatesInit();
    await this.fiberglassPoolTemplatesInit();

    console.log(this.fiberglassPoolTemplates);

    const _constructionDetailsTemplates = {};

    this.constructionDetailsTemplatesItems.data.map((el) => {
      _constructionDetailsTemplates[el.attributes.construction_type] = el.attributes;
    });
    this.constructionDetailsTemplates = _constructionDetailsTemplates;

    if (this.construction === 'Fiberglass') {
      this.setFiberglassPoolTemplate();
    }
    this.form = {
      ...this.form,
      construction: this.construction,
      shape: this.shape,
      custom_shape_text: this.customShapeText,
      width_ft: this.splitString(this.width || 0)[0],
      width_in: this.splitString(this.width || 0)[1],
      length_ft: this.splitString(this.length || 0)[0],
      length_in: this.splitString(this.length || 0)[1],
      shallow_ft: this.splitString(this.shallow || 0)[0],
      shallow_in: this.splitString(this.shallow || 0)[1],
      deepend_ft: this.splitString(this.deepend || 0)[0],
      deepend_in: this.splitString(this.deepend || 0)[1],
      construction_details: this.constructionDetails,
    };

    this.form.size_category =
      this.sizeCategory ||
      this.sizeAccordingToWidthAndLength(
        `${this.form.width_ft}.${this.form.width_in}`,
        `${this.form.length_ft}.${this.form.length_in}`
      );
    this.setSizeCategory(this.form.size_category);
  },
  methods: {
    ...mapActions({
      setRenovate: 'proposalWizard/setRenovate',
      megaUpdate: 'proposalWizard/megaUpdate',
      constructionDetailsTemplatesInit: 'constructionDetailsTemplates/index',

      fiberglassPoolTemplatesInit: 'fiberglassPoolTemplates/index',
    }),
    ...mapMutations({
      setConstruction: 'proposalWizard/SET_CONSTRUCTION',
      setShape: 'proposalWizard/SET_SHAPE',
      setCustomShapeText: 'proposalWizard/SET_CUSTOM_SHAPE_TEXT',
      setWidth: 'proposalWizard/SET_WIDTH',
      setLength: 'proposalWizard/SET_LENGTH',
      setSizeCategory: 'proposalWizard/SET_SIZE_CATEGORY',
      setShallow: 'proposalWizard/SET_SHALLOW',
      setDeepend: 'proposalWizard/SET_DEEPEND',
      setConstructionDetails: 'proposalWizard/SET_CONSTRUCTION_DETAILS',

      setFiberglassPoolTemplateId: 'proposalWizard/SET_FIBERGLASS_POOL_TEMPLATE_ID',
      setFiberglassDetails: 'proposalWizard/SET_FIBERGLASS_DETAILS',
      setShapeName: 'proposalWizard/SET_SHAPE_NAME',
      setIsFiberglassCustom: 'proposalWizard/SET_IS_FIBERGLASS_CUSTOM',
    }),
    constructionDetailsTemplate(val) {
      switch (val) {
        case 'Vinyl':
          return this.form.construction === this.construction
            ? this.constructionDetails
            : this.constructionDetailsTemplates['Vinyl']?.text || '';
        case 'Concrete':
          return this.form.construction === this.construction
            ? this.constructionDetails
            : this.constructionDetailsTemplates['Concrete']?.text || '';
        case 'Fiberglass':
          return this.form.construction === this.construction
            ? this.constructionDetails
            : this.constructionDetailsTemplates['Fiberglass']?.text || '';
        case 'Renovate':
          return this.form.construction === this.construction
            ? this.constructionDetails
            : this.constructionDetailsTemplates['Renovate']?.text || '';
        default:
          return '';
      }
    },
    sizeAccordingToWidthAndLength(w, l) {
      if (parseFloat(w) * parseFloat(l) > 800) {
        return 'Extra Large';
      } else if (parseFloat(w) * parseFloat(l) > 550) {
        return 'Large';
      } else if (parseFloat(w) * parseFloat(l) > 400) {
        return 'Medium';
      } else {
        return 'Small';
      }
    },
    setFiberglassPoolTemplate() {
      this.form.is_fiberglass_custom = this.isFiberglassCustom;
      this.form.fiberglass_pool_template_id = this.fiberglassPoolTemplateId
        ? `${this.fiberglassPoolTemplateId}`
        : this.fiberglassPoolTemplates.data[0].id;

      this.fiberglass_pool_template = this.form.fiberglass_pool_template_id
        ? this.fiberglassPoolTemplates.data.find((el) => el.id === this.form.fiberglass_pool_template_id).attributes
        : this.fiberglassPoolTemplates.data[0].attributes;

      this.setIsFiberglassCustom(this.form.is_fiberglass_custom);
      this.setFiberglassPoolTemplateId(this.form.fiberglass_pool_template_id);
    },
    onConstructionUpdate(val) {
      this.form.construction_details = this.constructionDetailsTemplate(val);
      if (this.form.construction === 'Renovate') {
        this.setRenovate(true);
      } else {
        if (this.isRenovate) {
          this.setRenovate(false);
        }
      }
      this.setConstruction(this.form.construction);
      this.setConstructionDetails(this.form.construction_details);

      if (this.form.construction === 'Fiberglass') {
        this.setFiberglassPoolTemplate();
      }
    },
    onCustomShapeTextUpdate() {
      this.setCustomShapeText(this.form.custom_shape_text);
    },
    onShapeUpdate() {
      this.setShape(this.form.shape);
    },
    onWidthUpdate() {
      if (this.form.width_in > 11) {
        this.form.width_ft = parseInt(this.form.width_ft) + 1;
        this.form.width_in = 0;
      }
      this.setWidth(`${this.form.width_ft.toString()}.${this.form.width_in.toString()}`);
      this.form.size_category = this.sizeAccordingToWidthAndLength(
        `${this.form.width_ft}.${this.form.width_in}`,
        `${this.form.length_ft}.${this.form.length_in}`
      );
      this.setSizeCategory(
        this.sizeAccordingToWidthAndLength(
          `${this.form.width_ft}.${this.form.width_in}`,
          `${this.form.length_ft}.${this.form.length_in}`
        )
      );
    },
    onLengthUpdate() {
      if (this.form.length_in > 11) {
        this.form.length_ft = parseInt(this.form.length_ft) + 1;
        this.form.length_in = 0;
      }
      this.setLength(`${this.form.length_ft.toString()}.${this.form.length_in.toString()}`);
      this.form.size_category = this.sizeAccordingToWidthAndLength(
        `${this.form.width_ft}.${this.form.width_in}`,
        `${this.form.length_ft}.${this.form.length_in}`
      );
      this.setSizeCategory(
        this.sizeAccordingToWidthAndLength(
          `${this.form.width_ft}.${this.form.width_in}`,
          `${this.form.length_ft}.${this.form.length_in}`
        )
      );
    },
    onSizeCategoryUpdate(evt) {
      this.setSizeCategory(evt.target.value);
    },
    onShallowUpdate() {
      if (this.form.shallow_in > 11) {
        this.form.shallow_ft = parseInt(this.form.shallow_ft) + 1;
        this.form.shallow_in = 0;
      }
      this.setShallow(`${this.form.shallow_ft.toString()}.${this.form.shallow_in.toString()}`);
    },
    onDeependUpdate() {
      if (this.form.deepend_in > 11) {
        this.form.deepend_ft = parseInt(this.form.deepend_ft) + 1;
        this.form.deepend_in = 0;
      }
      this.setDeepend(`${this.form.deepend_ft.toString()}.${this.form.deepend_in.toString()}`);
    },
    onConstructionDetailsUpdate() {
      this.setConstructionDetails(this.form.construction_details);
    },

    onFiberglassPoolTemplateUpdate(id) {
      this.setFiberglassPoolTemplateId(id);

      this.fiberglass_pool_template = this.fiberglassPoolTemplates.data.find((el) => el.id === `${id}`).attributes;
    },
    onIsFiberglassCustomChange() {
      this.setIsFiberglassCustom(this.form.is_fiberglass_custom);

      // if (this.form.is_fiberglass_custom) {
      // this.setFiberglassPoolTemplateId(null);
      // this.fiberglass_pool_template = null;
      // } else {
      if (!this.form.is_fiberglass_custom && !this.fiberglass_pool_template) {
        this.setFiberglassPoolTemplateId(this.form.fiberglass_pool_template_id);

        this.fiberglass_pool_template = this.fiberglassPoolTemplates.data.find(
          (el) => el.id === `${this.form.fiberglass_pool_template_id}`
        ).attributes;
      }
    },

    updateValidation() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        this.v_toast(400, 'Please, fill required fields!');
        return false;
      }

      return true;
    },

    checkIfFiberglassTemplateSelected() {
      if (!this.form.is_fiberglass_custom && this.construction === 'Fiberglass') {
        this.form.shape = this.fiberglass_pool_template.shape;
        this.form.width_ft = this.splitString(this.fiberglass_pool_template.width || 0)[0];
        this.form.width_in = this.splitString(this.fiberglass_pool_template.width || 0)[1];
        this.form.length_ft = this.splitString(this.fiberglass_pool_template.length || 0)[0];
        this.form.length_in = this.splitString(this.fiberglass_pool_template.length || 0)[1];
        this.form.shallow_ft = this.splitString(this.fiberglass_pool_template.shallow || 0)[0];
        this.form.shallow_in = this.splitString(this.fiberglass_pool_template.shallow || 0)[1];
        this.form.deepend_ft = this.splitString(this.fiberglass_pool_template.deepend || 0)[0];
        this.form.deepend_in = this.splitString(this.fiberglass_pool_template.deepend || 0)[1];

        this.setFiberglassDetails(this.fiberglass_pool_template.description);
        this.setShapeName(this.fiberglass_pool_template.shape_name);

        this.setShape(this.form.shape);
        this.setWidth(`${this.form.width_ft.toString()}.${this.form.width_in.toString()}`);
        this.setLength(`${this.form.length_ft.toString()}.${this.form.length_in.toString()}`);
        this.setShallow(`${this.form.shallow_ft.toString()}.${this.form.shallow_in.toString()}`);
        this.setDeepend(`${this.form.deepend_ft.toString()}.${this.form.deepend_in.toString()}`);
      } else {
        this.setFiberglassDetails(null);
        this.setShapeName(null);
      }
    },
    customOnUpdate() {
      this.checkIfFiberglassTemplateSelected();

      this.onUpdate(this.updateValidation);
    },
    customGoToPrev() {
      this.checkIfFiberglassTemplateSelected();

      this.goToPrev(this.updateValidation);
    },
    customGoToNext() {
      this.checkIfFiberglassTemplateSelected();

      this.goToNext(this.updateValidation);
    },
  },
};
</script>
